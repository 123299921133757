<template>
  <li class="jw-diary-list__item">
    <div class="jw-diary-list__divider">
      <div class="jw-diary-list__bullet"></div>
    </div>
    <div class="jw-diary-list__card">
      <!-- 신앙실천 요소 -->
      <div class="daily-check">
        <!-- <v-checkbox density="compact" hide-details color="#007AFF" label="말씀읽기"></v-checkbox> -->
        <span>말씀읽기 {{ dailyCheck.DailyCheck0000000001 }}장!!</span>
        <v-checkbox
          density="compact"
          hide-details
          color="#007AFF"
          label="기도"
          :value="state.pray"
          style="pointer-events: none; max-width: fit-content"
        ></v-checkbox>
        <v-checkbox
          density="compact"
          hide-details
          color="#007AFF"
          label="묵상"
          :value="state.qt"
          style="pointer-events: none; max-width: fit-content"
        ></v-checkbox>
        <v-checkbox
          density="compact"
          hide-details
          color="#007AFF"
          label="예수님 바라보기"
          :value="state.jesus"
          style="pointer-events: none; max-width: fit-content"
        ></v-checkbox>
        <v-checkbox
          density="compact"
          hide-details
          color="#007AFF"
          label="순종하기"
          :value="state.obey"
          style="pointer-events: none; max-width: fit-content"
        ></v-checkbox>
      </div>
    </div>
  </li>
</template>

<script setup>
import { isEmpty } from "@/utils/utils";
const props = defineProps({
  dailyCheck: {
    type: Object,
    default: null,
  },
});

/*
말씀읽기 DailyCheck0000000001
기도 DailyCheck0000000002 , 체크박스
묵상 DailyCheck0000000003 , 체크박스
예수님바라보기 DailyCheck0000000004, 퍼센트
순종하기 DailyCheck0000000005, 퍼센트
*/
const state = computed(() => {
  return {
    read: props.dailyCheck.DailyCheck0000000001,
    pray: !isEmpty(props.dailyCheck.DailyCheck0000000002) && props.dailyCheck.DailyCheck0000000002 > 0 ? true : false,
    qt: !isEmpty(props.dailyCheck.DailyCheck0000000003) && props.dailyCheck.DailyCheck0000000003 > 0 ? true : false,
    jesus: !isEmpty(props.dailyCheck.DailyCheck0000000004) && props.dailyCheck.DailyCheck0000000004 > 0 ? true : false,
    obey: !isEmpty(props.dailyCheck.DailyCheck0000000005) && props.dailyCheck.DailyCheck0000000005 > 0 ? true : false,
  };
});

watch(
  () => props.dailyCheck,
  (v) => {},
  {
    immediate: true,
    deep: true,
  }
);
</script>

<style scoped lang="scss">
// .daily-check {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   flex-wrap: wrap;
//   gap: 8px !important;
//   /* 요소 사이의 간격을 조절합니다. 필요에 따라 값을 조절하세요. */

//   .v-input {
//     margin-right: 0px !important;
//     max-width: fit-content !important;
//   }
// }
</style>
