<template>
  <div class="jw-diary-list__divider">
    <div class="jw-diary-list__bullet">
      <div class="day" :class="props.isTodayCard && 'green'" v-if="props.dayStart || props.emptyDay === true">
        <img width="24" height="24" v-show="props.weather" :src="`/assets/images/emotion_${props.weather}.png`" alt="행복해요" />
        <!-- <img width="24" height="24" :src="weatherUrl" alt="평범해요" /> -->
        {{ ddActor(props.diarySaveLDate) }}
        <!-- <small>{{ dayMatcher(props.wday) }}</small> -->
        <small>{{ formattedDay }}</small>
        <small v-if="props.emptyDay === false" class="time time-only mt-1">{{ timeActor(props.diarySaveLDate) }}</small>
      </div>

      <div v-else class="time">{{ timeActor(props.diarySaveLDate) }}</div>
    </div>
  </div>
</template>

<script setup>
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
import "dayjs/locale/zh-tw.js";
import "dayjs/locale/en.js";
import "dayjs/locale/id.js";
import { useI18n } from "vue-i18n";

const props = defineProps({
  isTodayCard: Boolean,
  emptyDay: Boolean,
  dayStart: Boolean,
  weather: String,
  diarySaveLDate: String,
  wday: [String, Number],
});

const { locale } = useI18n();

const dayjsLocale = computed(() => {
  return dayjs.locale(locale.value);
});

function ddActor(date) {
  const dayJsDate = dayjs(date, "YYYYMMDDHHmmss");
  return dayJsDate.format("DD");
}

function dayMatcher(number) {
  const matcher = {
    0: "월요일",
    1: "화요일",
    2: "수요일",
    3: "목요일",
    4: "금요일",
    5: "토요일",
    6: "일요일",
  };
  return matcher[number];
}

const formattedDay = computed(() => {
  dayjs.locale(locale.value || "ko");
  return dayjs()
    .day(props.wday + 1)
    .format("dddd");
});

function timeActor(dttm) {
  return dayjs(dttm).format("HH:mm");
}
</script>

<style scoped lang="scss"></style>
