<template>
  <li class="jw-diary-list__item">
    <div class="jw-diary-list__divider">
      <div class="jw-diary-list__bullet"></div>
    </div>
    <div class="jw-diary-list__card">
      <!-- 신앙실천 요소 -->
      <ul class="daily-check">
        <li>
          <VIcon size="16" :color="readActor(state.read.count)">jwIcons:svg/check-border</VIcon>
          <!-- 말씀읽기 {{ state.read.count || 0 }}장 -->
          {{ myInfo.dailycheck.DailyCheck0000000001.dailyCheckName }} {{ state.read.count || 0 }}장
        </li>
        <li>
          <VIcon size="16" :color="state.pray.chk ? '#007AFF' : '#C4C4C4'">jwIcons:svg/check-border</VIcon>
          {{ myInfo.dailycheck.DailyCheck0000000002.dailyCheckName }}
        </li>
        <li>
          <VIcon size="16" :color="state.qt.chk ? '#007AFF' : '#C4C4C4'">jwIcons:svg/check-border</VIcon>
          {{ myInfo.dailycheck.DailyCheck0000000003.dailyCheckName }}
        </li>
        <li>
          <VIcon size="16" :color="readActor(state.jesus.percent)">jwIcons:svg/check-border</VIcon>
          {{ myInfo.dailycheck.DailyCheck0000000004.dailyCheckName }} {{ state.jesus.percent || 0 }}%
        </li>
        <li>
          <VIcon size="16" :color="readActor(state.obey.percent)">jwIcons:svg/check-border</VIcon>
          {{ myInfo.dailycheck.DailyCheck0000000005.dailyCheckName }} {{ state.obey.percent || 0 }}%
        </li>
      </ul>
    </div>
  </li>
</template>

<script setup>
import { useDiaryStore } from "~/store/diary";
const diaryStore = useDiaryStore();

const props = defineProps({
  dailyCheck: Object,
});

const state = computed(() => {
  return {
    read: {
      chk: true,
      disable: !isEmpty(props.dailyCheck.DailyCheck0000000001) && props.dailyCheck.DailyCheck0000000001 > 0 ? false : true,
      count: props.dailyCheck.DailyCheck0000000001,
    },
    pray: {
      chk: !isFalsy(props.dailyCheck.DailyCheck0000000002) && props.dailyCheck.DailyCheck0000000002 > 0 ? true : false,
      disable: !isEmpty(props.dailyCheck.DailyCheck0000000002) && props.dailyCheck.DailyCheck0000000002 > 0 ? false : true,
    },

    qt: {
      chk: !isFalsy(props.dailyCheck.DailyCheck0000000003) && props.dailyCheck.DailyCheck0000000003 > 0 ? true : false,
      disable: !isEmpty(props.dailyCheck.DailyCheck0000000003) && props.dailyCheck.DailyCheck0000000003 > 0 ? false : true,
    },
    jesus: {
      chk: true,
      disable: !isEmpty(props.dailyCheck.DailyCheck0000000004) && props.dailyCheck.DailyCheck0000000004 > 0 ? false : true,
      percent: props.dailyCheck.DailyCheck0000000004,
    },
    obey: {
      chk: true,
      disable: !isEmpty(props.dailyCheck.DailyCheck0000000005) && props.dailyCheck.DailyCheck0000000005 > 0 ? false : true,
      percent: props.dailyCheck.DailyCheck0000000005,
    },
  };
});

const myInfo = computed(() => {
  return diaryStore.state.myInfo;
});

//console.log("myInfo :: ", myInfo.value);

function readActor(count) {
  if (!count || Number(count) == 0) return "#C4C4C4";
  if (Number(count) > 0) return "#007AFF";
  return "#C4C4C4";
}
</script>

<style scoped lang="scss"></style>
