<template>
  <li class="jw-diary-list__item" v-show="props.show">
    <div class="jw-diary-list__divider">
      <div class="jw-diary-list__bullet">
        <div class="day" :class="isTodayCard && 'green'" v-if="dayStart">
          <!-- <img width="24" height="24" src="~/assets/images/weather-sunny.png" alt="맑음" /> -->

          {{ ddActor(diarySaveLDate) }}
          <small>{{ dayMatcher(wday) }}</small>
          <small>{{ timeActor(diarySaveLDate) }}</small>
        </div>
        <div v-else>{{ timeActor(diarySaveLDate) }}</div>
      </div>
    </div>
    <div class="jw-diary-list__card" @click.stop="emits('open:editMoment', toDialogData)">
      <!-- 일상동행요소 -->
      <div class="moment">
        <!-- <div class="text">{{ diaryCnts }}</div> -->
        <div class="text" v-html="diaryCnts" />
        <!-- <div class="like">
          <VIcon class="mr-1">jwIcons:svg/heart</VIcon>
          {{ amenCnt }}
        </div> -->
      </div>
    </div>
  </li>
</template>

<script setup>
import dayjs from "dayjs";

const emits = defineEmits("open:editMoment");

const props = defineProps({
  show: {
    type: Boolean,
    default: true,
  },
  dayStart: {
    type: Boolean,
  },
  diaryTitle: {
    type: String,
  },
  diaryCnts: {
    type: String,
  },
  openCnt: {
    type: String,
  },
  readCount: {
    type: [String, Number],
  },
  amenCnt: {
    type: [String, Number],
  },
  rtime: {
    type: String,
  },
  uploadFileCd: {
    type: String,
  },
  wday: {
    type: [String, Number],
  },
  diarySaveLDate: {
    type: String,
  },
  diarySortDate: {
    type: String,
  },
  momentCd: String,
  diaryCd: String,
  memCd: String,
});

const toDialogData = computed(() => ({ ...props }));

const isTodayCard = computed(() => {
  const todayDate = dayjs(new Date()).format("YYYYMMDD");
  const thisCardDate = dayjs(props.diarySaveLDate).format("YYYYMMDD");
  if (todayDate == thisCardDate) {
    return true;
  } else return false;
});

function timeActor(dttm) {
  return dayjs(dttm).format("HH:mm");
}
function dayMatcher(number) {
  const matcher = {
    0: "월요일",
    1: "화요일",
    2: "수요일",
    3: "목요일",
    4: "금요일",
    5: "토요일",
    6: "일요일",
  };
  return matcher[number];
}

function ddActor(date) {
  const dayJsDate = dayjs(date, "YYYYMMDDHHmmss");
  return dayJsDate.format("DD");
}
</script>

<style scoped lang="scss"></style>
