import {
  blockingPostAPI,
  setAmenAPI,
  setColumnAmenAPI,
  getDiaryAmenInfoAPI,
  getColumnAmenInfoAPI,
  clearBlockingAPI,
  saveBookMarkAPI,
  delBookMarkAPI,
  getMyDiaryInfoAPI,
  getMyDiaryDailyAPI,
  getMyDiaryMonthAPI,
  getMyDiaryYearAPI,
  rcmdDiaryAPI,
  getBookMarkListAPI,
  getBookMarkInfoAPI,
  saveMyDiaryFileAPI,
} from "./api/diary";
import { isEmpty } from "@/utils/utils";
import dayjs from "dayjs";

import diary from "~/components/diary/diary.vue";
import dailyChk from "~/components/diary/dailyChk.vue";
import moment from "~/components/diary/moment.vue";

//import { useCommonStore } from "~/store/common";

export const useDiaryStore = defineStore("diary", () => {
  //const commonStore = useCommonStore();
  let state = reactive({
    myInfo: {},
    badgeList: [],
    list: [],
    monthList: [],
    yearList: [],
    dailyKind: "all",
    startNum: 0,
    tags: "",
    bookMarkList: [],
    bookMarkAllLength: 0,
    bookMarkInfo: {},
    resetDay: false,
    tabKind: "day",
    slideBtnList: [
      {
        value: "예수동행일기",
        active: false,
      },
      {
        value: "동행메모",
        active: false,
      },
      {
        value: "신앙 실천",
        active: false,
      },
      {
        value: "북마크",
        active: false,
      },
      {
        value: "예수동행일기 태그별 현황",
        active: false,
      },
    ],
  });

  /*
   * dateType : yearList, monthList, list
   * kind : diary, memo, all
   */
  function visibleActor({ dateType, kind }) {
    if (dateType == "list") {
      if (["diary", "moment"].includes(kind)) {
        state[dateType].forEach((itm) => {
          if (itm.gubun == kind) {
            itm.show = true;
          } else {
            itm.show = false;
          }
        });
        //state[dateType] = state[dateType].map(itm => ({ ...itm, show: itm.gubun == kind }));
        /* setTimeout(() => {
          state.resetDay = !state.resetDay;
        }, 200); */
      } else {
        state[dateType].forEach((itm) => {
          itm.show = true;
        });
        //state[dateType] = state[dateType].map(itm => ({ ...itm, show: true }));
      }
    }
  }

  async function blockingPostActor(payload) {
    try {
      const { statusText, data: res = {} } = await blockingPostAPI(payload);
      if (statusText !== "OK" || res.result === undefined) throw Error;
      return res.result;
    } catch (err) {
      console.error(`blockingPostActor err : `, err);
    }
  }

  async function clearBlockingActor(payload) {
    try {
      const { statusText, data: res = {} } = await clearBlockingAPI(payload);
      if (statusText !== "OK" || res.result === undefined) throw Error;
      return res.result;
    } catch (err) {
      console.error(`clearBlockingActor err : `, err);
    }
  }

  async function setAmenActor(payload) {
    try {
      const { statusText, data: res = {} } = await setAmenAPI(payload);
      if (statusText !== "OK") throw Error;
      return res;
    } catch (err) {
      console.error(`setAmenActor err : `, err);
    }
  }

  async function setColumnAmenActor(payload) {
    try {
      const { statusText, data: res = {} } = await setColumnAmenAPI(payload);
      if (statusText !== "OK") throw Error;

      return res;
    } catch (err) {
      console.error(`setColumnAmenActor err : `, err);
    }
  }

  /**
   *
   * @param {string} diaryCd
   */
  async function getAmenActor(payload) {
    try {
      const { statusText, data: res = {} } = await getDiaryAmenInfoAPI(payload);
      if (statusText !== "OK" || res.result === undefined) throw Error;
      return res.result;
    } catch (err) {
      console.error(`getAmenActor err : `, err);
    }
  }

  async function getColumnAmenActor(payload) {
    try {
      const { statusText, data: res = {} } = await getColumnAmenInfoAPI(payload);
      if (statusText !== "OK" || res.result === undefined) throw Error;

      return res.result;
    } catch (err) {
      console.error(`getColumnAmenActor err : `, err);
    }
  }

  /**
   * @param {diaryCd} 일기코드
   * @param {bMType} 북마크 구분(room, diary, column)
   * @param {bMCd} 북마크 코드
   * @param {wrtrCd} 일기/칼럼 작성자 회원코드
   */
  async function saveBookMarkActor(payload) {
    try {
      const { statusText, data: res = {} } = await saveBookMarkAPI(payload);
      return { result: res.result, bMCd: res.bMCd };
    } catch (err) {
      console.error(`saveBookMarkActor err : `, err);
    }
  }

  async function delBookMarkActor(payload) {
    const { statusText, data: res = {} } = await delBookMarkAPI(payload);

    return res.result;
  }

  async function getBookMarkListActor(payload) {
    const { statusText, data: res = {} } = await getBookMarkListAPI(payload);
    if (isEmpty(payload.bMType)) state.bookMarkAllLength = res.result.length;

    state.bookMarkList = res.result.map((itm) => ({
      ...itm,
      title: itm.bMTitle,
      text: itm.bMCnts,
      //name: itm.memName,
      memName: itm.memName,
      //background:url(/upload/column/20240312192849591385_thumb);
      imgSrc: itm.uploadFileCd ? `https://jwj.kr/upload/column/${itm.uploadFileCd}_thumb` : null,
      author: {
        name: itm.memName,
        imgSrc: itm.profilePhoto ? `https://jwj.kr/upload/member/${itm.profilePhoto}` : null,
        date: itm.bMDate,
        commentCount: 0,
        likeItCount: 0,
      },
    }));
    return state.bookMarkList;
  }

  async function getBookMarkInfoActor(payload) {
    const { statusText, data: res = {} } = await getBookMarkInfoAPI(payload);

    //state.bookMarkInfo = res.result[0];

    const prefix = "https://jwj.kr";
    const regex = /(<img src=")([^"]*)/g;
    const imgTagRegex = /<img[^>]*>/g;
    const regexSrc = /src="([^"]*)/i;

    state.bookMarkInfo = {
      ...res.result[0],
      bMCnts: res.result[0].bMCnts.replace(imgTagRegex, ``),
      ...(!isEmpty(res.result[0].bMCnts.match(regexSrc)) ? { headImg: `${prefix}${res.result[0].bMCnts.match(regexSrc)[1]}` } : { headImg: null }),
      pageAuthor: true,
      imgSrc: res.result[0].profilePhoto ? `https://jwj.kr/upload/member/${res.result[0].profilePhoto}` : null,
      date: dayjs(res.result[0].bMDate.replace("SS", "")).format("YYYY년 MM월 DD일 A hh:mm"),
      author: {
        //name: res.result[0].memName,
        memName: res.result[0].memName,
        imgSrc: res.result[0].profilePhoto ? `https://jwj.kr/upload/member/${res.result[0].profilePhoto}` : null,
        date: res.result[0].bMDate,
        commentCount: 0,
        likeItCount: 0,
      },
    };

    /* commonStore.pageAuthor = res.result[0].memName;
    commonStore.author = {
      name: res.result[0].memName,
      imgSrc: res.result[0].profilePhoto ? `https://jwj.kr/upload/member/${res.result[0].profilePhoto}` : null,
      date: res.result[0].bMDate,
      commentCount: 0,
      likeItCount: 0,
    }; */

    return state.bookMarkInfo;
  }

  function badgeKindActor(kind, score) {
    if (kind == "medal") {
      if (score >= 250) return "medal-gold";
      if (score >= 150) return "medal-silver";
      if (score >= 100) return "medal-bronze";
      else return null;
    } else if (kind == "star") {
      if (score >= 150) return "star-purple";
      if (score >= 100) return "star-red";
      if (score >= 50) return "star-blue";
      else return null;
    } else {
      if (score >= 150000) return "ribbon-brown";
      if (score >= 100000) return "ribbon-orange";
      if (score >= 50000) return "ribbon-green";
      else return null;
    }
  }

  async function getMyDiaryInfoActor(payload) {
    try {
      const { statusText, data: res = {} } = await getMyDiaryInfoAPI(payload);
      if (statusText !== "OK" || res?.mydiary === undefined) throw Error;
      console.log("res.mydiary ===> ", res.mydiary);
      res.mydiary.badge = {
        ...res.mydiary.badge,
        //star: { score: 2175, year: 2023, prize: "purple" },
        //medal: { score: 365, year: 2023, prize: "gold" },
      };

      let split04 = res.mydiary.dailycheck.DailyCheck0000000004.dailyCheckName.split("@");
      res.mydiary.dailycheck.DailyCheck0000000004 = {
        ...res.mydiary.dailycheck.DailyCheck0000000004,
        dailyCheckName: split04[0],
      };
      res.mydiary.dailycheck = {
        ...res.mydiary.dailycheck,
        ...{
          DailyCheck0000000005: {
            score: res.mydiary.dailycheck.DailyCheck0000000004.score2,
            dailyCheckName: split04[1],
            langMssgCd: "dwj.daily.chk.005",
          },
        },
      };

      state.badgeList = Object.keys(res.mydiary.badge).reduce((acc, cur) => {
        let obj = { [cur]: res.mydiary.badge[cur], kind: badgeKindActor(cur, res.mydiary.badge[cur].score), name: cur };
        /* console.log("cur===> ", cur);
        console.log("obj ===> ", obj); */
        acc.push(obj);
        return acc;
      }, []);
      console.log("state.badgeList ===> ", state.badgeList);

      Object.keys(res.mydiary).forEach((key) => {
        try {
          state.myInfo[key] = res.mydiary[key];
        } catch (error) {
          console.error(`in forEach err : `, error);
        }
      });

      return state;
    } catch (err) {
      console.error(`getMyDiaryInfoActor err : `, err);
    }
  }

  async function resetDailyActor(searchDate) {
    try {
      state.list = [];
      const { statusText, data: res = {} } = await getMyDiaryDailyAPI({
        searchDate,
      });
      if (statusText !== "OK" || res === undefined) throw Error;

      const dailyList = dailyDataActor(searchDate, res.result);

      state.list = [...dailyList];
      return state.list;
    } catch (err) {
      console.error(`resetDailyActor err : `, err);
    }
  }

  function compoMatcher(name) {
    const matcher = {
      diary: diary,
      moment: moment,
      dailyChk: dailyChk,
    };
    if (Object.keys(matcher).includes(name)) return matcher[name];
    //else dailyChk;
  }

  async function getGubunActor({ gubun, startNum = 0, tags }) {
    try {
      const { statusText, data: res = {} } = await getMyDiaryDailyAPI({ gubun, startNum, ...(!isEmpty(tags) && { tags }) });
      if (statusText !== "OK" || res === undefined) throw Error;
      if (startNum == 0 || startNum == "0") state.list = [];
      let dailyList = gubunDataActor(res.result);
      state.list = [...state.list, ...dailyList];

      return res.result;
    } catch (err) {
      console.error(`getGubunActor err : `, err);
    }
  }

  async function getDayActor({ searchDate }) {
    try {
      if (!searchDate) {
        searchDate = dayjs().format("YYYYMM");
      }
      const { statusText, data: res = {} } = await getMyDiaryDailyAPI({ searchDate });
      if (statusText !== "OK" || res === undefined) throw Error;
      let dailyList = [];
      dailyList = dailyDataActor({ searchDate }, res.result);
      state.list = [...state.list, ...dailyList];
      return state.list;
    } catch (err) {
      console.error(`getDayActor err : `, err);
    }
  }

  async function getMyDiaryDailyActor({ searchDate, direction }) {
    try {
      if (!searchDate) {
        searchDate = dayjs().format("YYYYMM");
      }
      if (!direction) direction = "bottom";

      const { statusText, data: res = {} } = await getMyDiaryDailyAPI({ searchDate });
      if (statusText !== "OK" || res === undefined) throw Error;

      // TODO: 필터 기능을 넣는다.(show값을 조정)
      //const dailyList = dailyDataActor({ searchDate }, res.result).map(itm => ({ ...itm, show: true }));
      let dailyList = [];
      if (state.dailyKind == "all") {
        dailyList = dailyDataActor({ searchDate }, res.result).map((itm) => ({ ...itm, show: true }));
      } else if (state.dailyKind == "diary") {
        dailyList = dailyDataActor({ searchDate }, res.result).map((itm) => ({
          ...itm,
          show: itm.gubun == "diary",
        }));
      } else {
        dailyList = dailyDataActor({ searchDate }, res.result).map((itm) => ({
          ...itm,
          show: itm.gubun == "moment",
        }));
      }

      //console.log("pinia dailyList :: ", dailyList)

      if (direction === "bottom") {
        state.list = [...state.list, ...dailyList];
      } else {
        state.list = [...dailyList, ...state.list];
      }
      return state.list;
    } catch (err) {
      console.error(`getMyDiaryDailyActor err : `, err);
    }
  }

  function gubunDataActor(array) {
    try {
      let newArray = [];
      for (const [idx, itm] of array.entries()) {
        const itmDate = dayjs(itm.diarySaveLDate);
        newArray.push({
          ...itm,
          saveDttm: itmDate.format("YYYYMMDD"),
          saveHHmm: itmDate.format("HHmm"),
          dailyCheck: itm.dailyCheck
            ? JSON.parse(itm.dailyCheck)
            : {
                DailyCheck0000000001: 0,
                DailyCheck0000000002: 0,
                DailyCheck0000000003: 0,
                DailyCheck0000000004: 0,
                DailyCheck0000000005: 0,
              },
          //gubun: 'dailyChk',
          id: `${itm.diarySaveLDate}-${idx}`,
          weekOfMonth: getWeekOfMonth(itmDate),
          week: dayjs(itmDate).week(),
          year: itmDate.format("YYYY"),
          month: itmDate.format("MM"),
          day: itmDate.format("DD"),
          wday: itmDate.day() === 0 ? 6 : itmDate.day() - 1,
          component: compoMatcher(itm.gubun),
          gubun: itm.gubun,
          emptyDay: false,
        });
      }

      const markList = processData(newArray);

      //return newArray;
      return markList;
    } catch (err) {
      console.error(`gubunDataActor err : `, err);
    }
  }
  function processData(data) {
    // 먼저 year, month, week, day, saveHHmm 기준으로 역순 정렬합니다.
    data.sort((a, b) => {
      const keyA = `${a.year}${a.month}${a.week}${a.day}${a.saveHHmm}`;
      const keyB = `${b.year}${b.month}${b.week}${b.day}${b.saveHHmm}`;
      return keyB.localeCompare(keyA); // 문자열 비교로 역순 정렬
    });

    // 정렬된 데이터를 순회하면서, 조건에 맞는 항목에 dayStart: true를 추가합니다.
    let lastDateKey = ""; // 마지막으로 처리된 날짜 키
    data.forEach((item) => {
      const currentDateKey = `${item.year}${item.month}${item.week}${item.day}`;
      if (currentDateKey !== lastDateKey) {
        // 날짜가 변경되었다면, 현재 항목이 그 날짜의 가장 나중 데이터임
        item.dayStart = true;
        lastDateKey = currentDateKey; // 마지막으로 처리된 날짜 키 업데이트
      } else {
        // 동일 날짜 내 이전 데이터가 존재한다면, dayStart를 false로 설정
        item.dayStart = false;
      }
    });

    return data;
  }

  function dailyDataActor(searchDate, result) {
    try {
      if (typeof searchDate == "object") {
        searchDate = searchDate.searchDate;
      }

      let newArray = [];
      for (const [idx, itm] of result.entries()) {
        const itmDate = dayjs(itm.diarySaveLDate);
        newArray.push({
          ...itm,
          saveDttm: itmDate.format("YYYYMMDD"),
          saveHHmm: itmDate.format("HHmm"),
          dailyCheck: itm.dailyCheck
            ? JSON.parse(itm.dailyCheck)
            : {
                DailyCheck0000000001: 0,
                DailyCheck0000000002: 0,
                DailyCheck0000000003: 0,
                DailyCheck0000000004: 0,
                DailyCheck0000000005: 0,
              },
          //gubun: 'dailyChk',
          id: `${itm.diarySaveLDate}-${idx}`,
          weekOfMonth: getWeekOfMonth(itmDate),
          week: dayjs(itmDate).week(),
          year: itmDate.format("YYYY"),
          month: itmDate.format("MM"),
          day: itmDate.format("DD"),
          wday: itmDate.day() === 0 ? 6 : itmDate.day() - 1,
          component: compoMatcher(itm.gubun),
          gubun: itm.gubun,
        });
      }

      let calendarData = createMonthDays(searchDate || dayjs().format("YYYYMM"));

      let updatedCalendarData = [...calendarData];

      // 이미 처리된 saveDttm 값을 추적하기 위한 집합(Set)
      let processedDates = new Set();

      for (let i = 0, max = newArray.length; i < max; i++) {
        const currentItem = newArray[i];

        // 현재 항목의 saveDttm이 이미 처리되었다면, 다음 항목으로 넘어감
        if (processedDates.has(currentItem.saveDttm)) {
          continue;
        }

        // 현재 saveDttm과 일치하는 모든 항목을 찾음
        const matchedItems = newArray.filter((itm) => itm.saveDttm == currentItem.saveDttm);

        // 현재 saveDttm을 처리된 집합에 추가
        processedDates.add(currentItem.saveDttm);

        // matchedItems를 updatedCalendarData에 삽입하는 로직 (기존 로직 유지)
        for (let j = 0; j < updatedCalendarData.length; j++) {
          if (updatedCalendarData[j].saveDttm == currentItem.saveDttm) {
            updatedCalendarData.splice(j, 1, ...matchedItems);
            break;
          }
        }
      }

      let prevDay = null;
      for (let i = 0, max = updatedCalendarData.length; i < max; i++) {
        if (updatedCalendarData[i].day !== prevDay) {
          updatedCalendarData[i].dayStart = true;
          prevDay = updatedCalendarData[i].day;
        } else {
          updatedCalendarData[i].dayStart = false;
        }
      }

      return updatedCalendarData;
    } catch (err) {
      console.error(`dailyDataActor err : `, err);
    }
  }

  function createMonthDays(yearMonth) {
    // 현재 날짜를 얻습니다.
    const today = new Date();

    // YYYYMM 문자열을 분리하여 년도와 월을 얻습니다.
    const year = parseInt(yearMonth.slice(0, 4));
    const month = parseInt(yearMonth.slice(4)) - 1; // 자바스크립트에서 월은 0부터 시작합니다.

    // 해당 월의 마지막 날짜를 얻습니다.
    const lastDayOfMonth = new Date(year, month + 1, 0).getDate();

    // 객체 배열을 생성합니다.
    const arrayOfObjects = [];

    for (let day = lastDayOfMonth; day >= 1; day--) {
      // YYYYMMDD 형식의 날짜 문자열을 생성합니다.
      const YYYYMMDD = `${year}${String(month + 1).padStart(2, "0")}${String(day).padStart(2, "0")}`;

      // 함수를 실행한 실제 날짜보다 앞선 날짜가 나오지 않도록 합니다.
      const dateObject = new Date(year, month, day);
      if (dateObject > today) continue;

      // 객체를 생성하고 배열에 추가합니다.
      arrayOfObjects.push({
        saveDttm: YYYYMMDD,
        diarySaveLDate: YYYYMMDD,
        searchDate: yearMonth,
        weekOfMonth: getWeekOfMonth(dayjs(YYYYMMDD)),
        week: dayjs(YYYYMMDD).week(),
        year: dayjs(YYYYMMDD).format("YYYY"),
        month: dayjs(YYYYMMDD).format("MM"),
        day: dayjs(YYYYMMDD).format("DD"),
        wday: dayjs(YYYYMMDD).day() === 0 ? 6 : dayjs(YYYYMMDD).day() - 1,
        component: compoMatcher("diary"),
        emptyDay: true,
        gubun: "diary",
        show: true,
      });
    }

    return arrayOfObjects;
  }

  function getWeekOfMonth(date) {
    const startWeekDayIndex = date.clone().startOf("month").day();
    const currentDayIndex = date.date();

    return Math.ceil((currentDayIndex + startWeekDayIndex) / 7);
  }

  async function resetMonthActor(searchDate) {
    try {
      const { statusText, data: res = {} } = await getMyDiaryMonthAPI({
        searchDate,
      });
      if (statusText !== "OK" || res?.result === undefined) throw Error;
      state.monthList = [];
      state.monthList = [
        ...res.result.map((itm) => ({
          ...itm,
          dailyCheck: JSON.parse(itm.dailyCheck),
          ...(!isEmpty(itm.tag) && { tag: JSON.parse(itm.tag) }),
        })),
      ];

      return state.monthList;
    } catch (err) {
      console.error(`resetMonthActor err : `, err);
    }
  }

  /* async function getMyDiaryMonthActor_(payload) {
    try {
      const { statusText, data: res = {} } = await getMyDiaryMonthAPI(payload);
      if (statusText !== 'OK' || res?.result === undefined) throw Error;
      
      state.monthList = [
        ...res.result.map(itm => ({ ...itm, dailyCheck: JSON.parse(itm.dailyCheck), ...(!isEmpty(itm.tag) && { tag: JSON.parse(itm.tag) }) }))
      ];

      

      return state.monthList;
    } catch (err) {
      console.error(`getMyDiaryMonthActor err : `, err);
    }
  } */

  /* async function getMyDiaryMonthActor(YYYY, MM) {
    try {
      const joinDttm = dayjs(state.myInfo.info.joindate, "YYYYMMDD").format("YYYYMM");

      
      

      const currentYear = new Date().getFullYear();

      const currentMonth = new Date().getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더함
      const startMonth = MM !== undefined ? MM : parseInt(YYYY, 10) === currentYear ? currentMonth : 12;
      let yearMonthList = [];
      

      for (let month = startMonth; month > 0; month--) {
        const yearMonth = `${YYYY}${month.toString().padStart(2, "0")}`;

        if (yearMonth >= joinDttm) {
          yearMonthList.push(yearMonth);
        } else {
          break;
        }
      }

      
      const defaultData = yearMonthList.map((yearMonth) => ({
        dailyCheck: {
          DailyCheck0000000001: 0,
          DailyCheck0000000002: "0%",
          DailyCheck0000000003: "0%",
          DailyCheck0000000004: "0%",
          DailyCheck0000000005: "0%",
        },
        diaryCount: 0,
        momentCount: 0,
        readCount: 0,
        searchDate: yearMonth,
      }));

      // 서버에서 데이터를 가져옵니다.
      const { statusText, data: res = {} } = await getMyDiaryMonthAPI({
        searchDate: YYYY || dayjs().format("YYYY"),
      });
      if (statusText !== "OK" || res?.result === undefined) throw Error;

      const updatedData = defaultData.map((data) => {
        let matchedData = res.result.find((serverDatum) => serverDatum.searchDate === data.searchDate);
        //matchedData.dailyCheck = JSON.parse(matchedData.dailyCheck);
        if (matchedData) matchedData.dailyCheck = JSON.parse(matchedData.dailyCheck);
        return matchedData ? matchedData : data;
      });

      state.monthList = [...state.monthList, ...updatedData];
      return state.monthList;
      //return updatedData;
    } catch (err) {
      console.error(`getMyDiaryMonthActor err : `, err);
    }
  } */
  async function getMyDiaryMonthActor_(YYYY, MM, isStart) {
    try {
      const joinDttm = dayjs(state.myInfo.info.joindate, "YYYYMMDD").format("YYYYMM");

      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더함

      let startMonth;
      if (MM !== undefined) {
        // MM이 정의된 경우
        startMonth = MM;
      } else {
        // MM이 정의되지 않은 경우
        if (parseInt(YYYY, 10) === currentYear) {
          // YYYY가 현재 연도와 동일
          startMonth = currentMonth;
        } else {
          // YYYY가 현재 연도와 동일하지 않음
          startMonth = 12;
        }
      }

      let yearMonthList = [];
      for (let month = startMonth; month > 0; month--) {
        const yearMonth = `${YYYY}${month.toString().padStart(2, "0")}`;
        if (yearMonth >= joinDttm) {
          yearMonthList.push(yearMonth);
        } else {
          // joinDttm 이전 날짜에 도달하면 반복 중단
          break;
        }
      }

      const defaultData = yearMonthList.map((yearMonth) => ({
        dailyCheck: {
          DailyCheck0000000001: 0,
          DailyCheck0000000002: "0%",
          DailyCheck0000000003: "0%",
          DailyCheck0000000004: "0%",
          DailyCheck0000000005: "0%",
        },
        diaryCount: 0,
        momentCount: 0,
        readCount: 0,
        searchDate: yearMonth,
      }));

      // 서버에서 데이터를 가져옵니다.
      const { statusText, data: res = {} } = await getMyDiaryMonthAPI({
        searchDate: YYYY || dayjs().format("YYYY"),
      });
      if (statusText !== "OK" || res?.result === undefined) throw Error;

      console.log("res.result ===> ", res.result);

      const updatedData = defaultData.map((data) => {
        let matchedData = res.result.find((serverDatum) => serverDatum.searchDate === data.searchDate);
        if (matchedData) matchedData.dailyCheck = JSON.parse(matchedData.dailyCheck);
        return matchedData ? matchedData : data;
      });

      console.log("updatedData ===> ", updatedData);

      if (isStart) {
        state.monthList = [...updatedData];
      } else {
        state.monthList = [...state.monthList, ...updatedData];
        return state.monthList;
      }
      /* state.monthList = [...state.monthList, ...updatedData];
      return state.monthList; */
      //state.monthList = [...updatedData];
      return state.monthList;
    } catch (err) {
      console.error(`getMyDiaryMonthActor err : `, err);
    }
  }

  async function getMyDiaryMonthActor(YYYY, MM, isStart) {
    try {
      const joinDttm = dayjs(state.myInfo.info.joindate, "YYYYMMDD").format("YYYYMM");
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1; // getMonth()는 0부터 시작하므로 1을 더함

      // 시작 월을 설정, YYYY가 올해인 경우 현재 월보다 큰 월을 제외
      let startMonth;
      if (MM !== undefined) {
        startMonth = MM;
      } else {
        startMonth = parseInt(YYYY, 10) === currentYear ? currentMonth : 12;
      }

      // 12월부터 1월까지 배열 생성, joinDttm과 비교해 필터링
      let yearMonthList = [];
      for (let month = startMonth; month > 0; month--) {
        const yearMonth = `${YYYY}${month.toString().padStart(2, "0")}`;

        // joinDttm 이후의 월만 포함하고, 미래의 월은 제외
        if (parseInt(yearMonth, 10) >= parseInt(joinDttm, 10) && !(parseInt(YYYY) === currentYear && month > currentMonth)) {
          yearMonthList.push(yearMonth);
        }
      }

      console.log("Generated yearMonthList: ", yearMonthList); // yearMonthList 확인

      const defaultData = yearMonthList.map((yearMonth) => ({
        dailyCheck: {
          DailyCheck0000000001: 0,
          DailyCheck0000000002: "0%",
          DailyCheck0000000003: "0%",
          DailyCheck0000000004: "0%",
          DailyCheck0000000005: "0%",
        },
        diaryCount: 0,
        momentCount: 0,
        readCount: 0,
        searchDate: yearMonth,
      }));

      // 서버에서 데이터를 가져옵니다.
      const { statusText, data: res = {} } = await getMyDiaryMonthAPI({
        searchDate: YYYY || dayjs().format("YYYY"),
      });
      if (statusText !== "OK" || res?.result === undefined) throw new Error();

      console.log("Server data result: ", res.result); // 서버에서 11월과 12월 데이터를 받는지 확인

      // 기본 데이터와 서버에서 받은 데이터를 병합
      const updatedData = defaultData.map((data) => {
        let matchedData = res.result.find((serverDatum) => serverDatum.searchDate === data.searchDate);

        // dailyCheck가 문자열인지 확인 후 파싱, 아니면 그대로 사용
        if (matchedData && typeof matchedData.dailyCheck === "string") {
          matchedData.dailyCheck = JSON.parse(matchedData.dailyCheck);
        }

        return matchedData ? matchedData : data;
      });

      console.log("Updated data after merging: ", updatedData); // merged된 결과 확인

      if (isStart) {
        state.monthList = [...updatedData];
      } else {
        state.monthList = [...state.monthList, ...updatedData];
        return state.monthList;
      }

      return state.monthList;
    } catch (err) {
      console.error(`getMyDiaryMonthActor err : `, err);
    }
  }

  async function getMyDiaryYearActor(joinYear) {
    try {
      const currentYear = new Date().getFullYear();

      const years = Array.from({ length: currentYear - joinYear + 1 }, (_, i) => (currentYear - i).toString());

      const defaultTemplate = {
        dailyCheck: [
          { name: "말씀읽기", value: 0 },
          { name: "기도", value: 0 },
          { name: "묵상", value: 0 },
          { name: "예수님바라보기", value: 0 },
          { name: "순종하기", value: 0 },
        ],
        diaryCount: 0,
        momentCount: 0,
        readCount: 0,
        searchDate: "2023",
        tag: { 감사: "0%", 기쁨: "0%", 사랑: "0%" },
        tagAll: [
          { tagCd: 9, 감사: "0%" },
          { tagCd: 10, 기쁨: "0%" },
          { tagCd: 8, 사랑: "0%" },
        ],
      };

      const defaultData = years.map((year) => ({
        ...defaultTemplate,
        searchDate: year,
      }));

      const { statusText, data: res = {} } = await getMyDiaryYearAPI();
      if (statusText !== "OK" || res?.result === undefined) throw Error;

      res.result = res.result.map((itm) => ({
        ...itm,
        dailyCheck: Object.entries(JSON.parse(itm.dailyCheck)).map(([name, value]) => ({
          name: dailyMatcher[name],
          value: parseInt(value),
        })),
        ...(!isEmpty(itm.tag) && {
          tag: Object.entries(JSON.parse(itm.tag)).map(([name, value]) => ({
            name,
            value: parseInt(value),
          })),
        }),
      }));

      const updatedData = defaultData.map((data) => {
        const matchedData = res.result.find((serverDatum) => serverDatum.searchDate === data.searchDate);
        return matchedData ? { ...data, ...matchedData } : data;
      });

      state.yearList = updatedData;

      return state.yearList;
    } catch (err) {
      console.error(`getMyDiaryYearActor err : `, err);
    }
  }

  async function getMyDiaryYearActor_(joinYear) {
    try {
      const { statusText, data: res = {} } = await getMyDiaryYearAPI();
      if (statusText !== "OK" || res?.result === undefined) throw Error;

      const defaultTemplate = {
        dailyCheck: [
          { name: "말씀읽기", value: 0 },
          { name: "기도", value: 0 },
          { name: "묵상", value: 0 },
          { name: "예수님바라보기", value: 0 },
          { name: "순종하기", value: 0 },
        ],
        diaryCount: 0,
        momentCount: 0,
        readCount: 0,
        searchDate: "2023",
        //tag: null,
        tagAll: [
          { tagCd: 9, 감사: "0%" },
          { tagCd: 10, 기쁨: "0%" },
          { tagCd: 8, 사랑: "0%" },
        ],
      };

      //state.yearList = [...res.result.map(itm => ({ ...itm, dailyCheck: JSON.parse(itm.dailyCheck), tag: JSON.parse(itm.tag) }))];
      state.yearList = [
        ...res.result.map((itm) => ({
          ...itm,
          //dailyCheck: JSON.parse(itm.dailyCheck),
          dailyCheck: Object.entries(JSON.parse(itm.dailyCheck)).map(([name, value]) => ({
            name: dailyMatcher[name],
            value: parseInt(value),
          })),
          ...(!isEmpty(itm.tag) && {
            tag: Object.entries(JSON.parse(itm.tag)).map(([name, value]) => ({
              name,
              value: parseInt(value),
            })),
          }),
        })),
      ];

      return state.yearList;
    } catch (err) {
      console.error(`getMyDiaryYearActor err : `, err);
    }
  }

  const dailyMatcher = {
    DailyCheck0000000001: "말씀읽기",
    DailyCheck0000000002: "기도",
    DailyCheck0000000003: "묵상",
    DailyCheck0000000004: "예수님바라보기",
    DailyCheck0000000005: "순종하기",
  };

  async function rcmdDiaryActor(payload) {
    try {
      const { data: res = {} } = await rcmdDiaryAPI(payload);
      return res;
    } catch (err) {
      console.error(`rcmdDiaryActor err : `, err);
    }
  }

  async function saveMyDiaryActor(payload) {
    try {
      const { statusText, data: res = {} } = await saveMyDiaryFileAPI(payload);
      //console.log("saveMyDiaryActor res ===> ", res);
      return res;
    } catch (err) {
      console.error(`saveMyDiaryActor err : `, err);
    }
  }

  return {
    blockingPostActor,
    clearBlockingActor,
    setColumnAmenActor,
    setAmenActor,
    getAmenActor,
    getColumnAmenActor,
    saveBookMarkActor,
    delBookMarkActor,
    getBookMarkListActor,
    getBookMarkInfoActor,
    getMyDiaryInfoActor,
    getMyDiaryDailyActor,
    getMyDiaryMonthActor,
    getMyDiaryYearActor,
    resetDailyActor,
    resetMonthActor,
    visibleActor,
    rcmdDiaryActor,
    getDayActor,
    getGubunActor,
    saveMyDiaryActor,
    state,
  };
});
