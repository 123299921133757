<template>
  <div class="diary__thumbs">
    <!-- <VImg :width="isPc ? 130 : 81" height="100%" :aspect-ratio="isPc ? 130 / 98 : 81 / 117" cover src="https://picsum.photos/500"> -->
    <VImg
      v-if="!isEmpty(props.uploadFileCd)"
      :width="isPc ? 130 : 81"
      height="100%"
      :aspect-ratio="isPc ? 130 / 98 : 81 / 117"
      cover
      :src="`https://jwj.kr/upload/diary/${props.uploadFileCd}_thumb`"
    >
      <template v-slot:placeholder>
        <div class="d-flex align-center justify-center fill-height">
          <v-progress-circular color="grey-lighten-4" indeterminate></v-progress-circular>
        </div>
      </template>
    </VImg>
  </div>
</template>

<script setup>
import { isEmpty } from "~/utils/utils";

const props = defineProps({
  uploadFileCd: String,
});
</script>

<style scoped lang="scss"></style>
