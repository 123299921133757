<template>
  <li class="jw-diary-list__item" @click.prevent.stop="emits('move:toPage')">
    <DiaryDate v-bind="props" />
    <div class="jw-diary-list__card" :class="props.tempSaveYn == 'Y' && 'writing'" v-if="props.emptyDay === false">
      <div class="diary">
        <DiaryContent v-bind="props" />
        <DiaryImage v-bind="props" />
      </div>
    </div>
    <DiaryEmpty v-bind="props" v-else-if="props.emptyDay === true" />
  </li>
</template>

<script setup>
import DiaryDate from "@/components/diary/diaryCard/parts/DiaryDate.vue";
import DiaryContent from "@/components/diary/diaryCard/parts/DiaryContent.vue";
import DiaryImage from "@/components/diary/diaryCard/parts/DiaryImage.vue";
import DiaryEmpty from "@/components/diary/diaryCard/parts/DiaryEmpty.vue";

const props = defineProps({
  isTodayCard: Boolean,
  dayStart: Boolean,
  emptyDay: Boolean,
  weather: String,
  diarySaveLDate: String,
  wday: [String, Number],
  tempSaveYn: String,
  diaryTitle: String,
  diaryCnts: String,
  postCount: [Number, String],
  amenCnt: [Number, String],
  tags: String,
  uploadFileCd: String,
});

const emits = defineEmits("move:toPage");
</script>

<style scoped lang="scss"></style>
