<template v-if="props">
  <DiaryItem v-bind="props" :isTodayCard @move:toPage="onClickMoveToPage" />
  <DiaryItemChk v-bind="props" v-if="props.emptyDay == false && props.tempSaveYn != 'Y'" />
</template>

<script setup>
import dayjs from "dayjs";
import DiaryItem from "@/components/diary/diaryCard/DiaryItem.vue";
import DiaryItemChk from "@/components/diary/diaryCard/DiaryItemChk.vue";
//import { isEmpty, isFalsy } from "@/utils/utils";

defineComponent({
  name: "diaryComponent",
});
const router = useRouter();
const props = defineProps({
  show: {
    type: Boolean,
    deafult: true,
  },
  emptyDay: {
    type: Boolean,
    default: false,
  },
  dayStart: {
    type: Boolean,
  },
  diaryTitle: {
    type: String,
  },
  diaryCnts: {
    type: String,
  },
  postCount: {
    type: [String, Number],
  },
  openCnt: {
    type: String,
  },
  readCount: {
    type: [String, Number],
  },
  amenCnt: {
    type: [String, Number],
  },
  rtime: {
    type: String,
  },
  uploadFileCd: {
    type: String,
  },
  wday: {
    type: [String, Number],
  },
  diarySaveLDate: {
    type: String,
  },
  diarySortDate: {
    type: String,
  },
  dailyCheck: {
    type: Object,
  },
  tags: {
    type: String,
    default: "",
  },
  diaryCd: {
    type: String,
  },
  weather: String,
  tempSaveYn: String,
});

//const weatherUrl = computed(() => `/assets/images/emotion_${props.weather}.png`);

const isTodayCard = computed(() => {
  const todayDate = dayjs(new Date()).format("YYYYMMDD");
  const thisCardDate = dayjs(props.diarySaveLDate).format("YYYYMMDD");
  if (todayDate == thisCardDate) {
    return true;
  } else return false;
});

watch(
  () => props,
  (value) => {
    //
  },
  { deep: true, immediate: true }
);

function onClickMoveToPage() {
  if (!props.diaryCd) return false;

  if (props.tempSaveYn == "Y") {
    router.push(`/write?edit=${props.diaryCd}`);
  } else {
    router.push(`/diary/${props.diaryCd}`);
  }
}

function showProps() {
  if (props.tempSaveYn == "Y") {
    router.push(`/write?edit=${props.diaryCd}`);
  } else {
    router.push(`/diary/${props.diaryCd}`);
  }
}
</script>
